<template>
  <div>
    <div class="pageTitle">群組管理</div>
    <div class="borderColor mb-4">
      <!-- 為跟其他類似頁面一致所設 div wrap -->
      <div class="d-flex m-2 mb-3">
        <button
          type="button"
          class="confirmButton p-1 my-1 ml-auto"
          style="width: 120px"
          @click="handleUpdateGroupData"
        >
          更新群組
        </button>
      </div>

      <div class="tableWrap">
        <table class="text-center">
          <tr class="sticky-top">
            <th v-for="(tableTitle, thIdx) in thTitle" :key="thIdx">
              {{ tableTitle }}
            </th>
            <th>設定</th>
          </tr>

          <tr v-for="(group, groupIdx) in data" :key="groupIdx">
            <td>{{ group.role }}</td>

            <td>
              <b-form-checkbox-group
                v-model="data[groupIdx].sideMenu"
                :options="sideMenuOptions"
                plain
                stacked
                @change="sortSelectedSideMenu(groupIdx)"
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="data[groupIdx].componentMenu"
                :options="componentMenuOptions"
                plain
                stacked
                @change="sortSelectedComponentMenu(groupIdx)"
              />
            </td>

            <td>
              <div
                v-for="(obj, updateIdx) in data[groupIdx].othersMenu"
                :key="updateIdx"
                class="mb-5"
              >
                <b-form-checkbox
                  v-model="obj.hasPermission"
                  plain
                  @change="
                    handleSelectedOthersMenuTitle(
                      obj.hasPermission,
                      groupIdx,
                      updateIdx
                    )
                  "
                >
                  {{ obj.menuName }}
                </b-form-checkbox>
                <div
                  class="selection"
                  :class="{
                    invalid: obj?.hasPermission && !obj?.list?.length,
                  }"
                >
                  <div class="my-2">
                    {{ othersMenuList[updateIdx].listName }}:
                  </div>
                  <multiselect
                    ref="multiselect"
                    v-model="obj.list"
                    :options="othersMenuList[updateIdx].options"
                    placeholder="請選擇"
                    :max-height="300"
                    selectLabel="select"
                    deselectLabel="remove"
                    :preserveSearch="true"
                    :closeOnSelect="false"
                    :clearOnSelect="false"
                    :searchable="false"
                    :multiple="true"
                    :limit="2"
                    :disabled="!obj?.hasPermission"
                    @input="sortSelectedOthersMenuList(groupIdx, updateIdx)"
                  />
                  <label
                    class="typo__label form__label"
                    v-show="obj?.hasPermission && !obj?.list?.length"
                  >
                    請至少選擇一項
                  </label>
                </div>
              </div>
            </td>

            <td>
              <button
                type="button"
                class="btn dangerButton text-white p-1 m-1"
                style="width: 120px"
                @click="handleDeleteGroupData(group.role, group.id)"
              >
                刪除群組
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                placeholder="輸入等級名稱"
                v-model="createRoleName"
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="createSideMenu"
                :options="sideMenuOptions"
                plain
                stacked
                @change="sortSelectedSideMenu('createNew')"
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="createComponentMenu"
                :options="componentMenuOptions"
                plain
                stacked
                @change="sortSelectedComponentMenu('createNew')"
              />
            </td>

            <td>
              <div
                v-for="(createObj, createIdx) in createOthersMenu"
                :key="createIdx"
                class="mb-5"
              >
                <b-form-checkbox
                  v-model="createObj.hasPermission"
                  plain
                  @change="
                    handleSelectedOthersMenuTitle(
                      createObj.hasPermission,
                      'createNew',
                      createIdx
                    )
                  "
                >
                  {{ createObj.menuName }}
                </b-form-checkbox>
                <div
                  class="selection"
                  :class="{
                    invalid:
                      createObj?.hasPermission && !createObj?.list?.length,
                  }"
                >
                  <div class="my-2">
                    {{ othersMenuList[createIdx].listName }}:
                  </div>
                  <multiselect
                    ref="multiselect"
                    v-model="createObj.list"
                    :options="othersMenuList[createIdx].options"
                    placeholder="請選擇"
                    :max-height="300"
                    selectLabel="select"
                    deselectLabel="remove"
                    :preserveSearch="true"
                    :closeOnSelect="false"
                    :clearOnSelect="false"
                    :searchable="false"
                    :multiple="true"
                    :limit="2"
                    :disabled="!createObj?.hasPermission"
                    @input="sortSelectedOthersMenuList('createNew', createIdx)"
                  />
                  <label
                    class="typo__label form__label"
                    v-show="
                      createObj?.hasPermission && !createObj?.list?.length
                    "
                  >
                    請至少選擇一項
                  </label>
                </div>
              </div>
            </td>

            <td>
              <button
                type="button"
                class="btn generallyButton text-white"
                style="width: 120px"
                @click="handleCreateGroupData"
              >
                新增群組
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '../router/index'
import { mapState, mapActions, mapMutations } from 'vuex'
import { createGroupApi, updateGroupApi, deleteGroupApi } from '../api'
import Multiselect from 'vue-multiselect'

const createOthersMenuDefault = [
  {
    menuName: '即時資訊 - 案場狀態告警',
    hasPermission: false,
    list: []
  },
  {
    menuName: '即時資訊 - 案場資訊(即時資訊)',
    hasPermission: false,
    list: []
  }
  // {
  //   menuName: '測試測試',
  //   hasPermission: false,
  //   list: []
  // }
]

// prepare siteMenu, componentMenu options
const siteRoutes = routes.find(
  (x) => x.component && x.component.name === 'langRouterView'
)
const releaseSite = siteRoutes.children.filter((x) => x.meta && x.meta.release)
const releaseTopSite = siteRoutes.children
  .find((x) => x.path === 'siteinfo')
  .children.filter((x) => x.meta && x.meta.release)

export default {
  name: 'GroupManage',
  components: {
    Multiselect
  },
  data () {
    return {
      thTitle: ['等級', '可觀看 SIDE 網頁', '可觀看 TOP 網頁', '可使用項目'],
      data: [],
      othersMenuList: [
        // 用來 map 資料
        {
          menuName: '即時資訊 - 案場狀態告警',
          listName: '告警等級顯示項目',
          options: [
            '影響發電',
            '發電效率不佳',
            '不影響發電',
            '系統未連線',
            '系統短暫斷線'
          ]
        },
        {
          menuName: '即時資訊 - 案場資訊(即時資訊)',
          listName: '即時資訊顯示項目',
          options: [
            '目前日照強度 (w/m²)',
            '今日日照量 (kWh/m²)',
            '今日峰瓩值 (kWh/kWp)',
            '今日 PR (%)',
            '低壓功率 (kW)',
            '高壓功率 (kW)',
            '模組溫度 (°C)',
            '環境溫度 (°C)',
            '即時風速 (m/s)',
            '即時風向 (°)',
            '即時水位值 (m)'
          ]
        }
        // {
        //   menuName: '測試測試',
        //   listName: '測試顯示項目',
        //   options: [
        //     '123', '321'
        //   ]
        // }
      ],
      createRoleName: '',
      createSideMenu: [],
      createComponentMenu: [],
      createOthersMenu: JSON.parse(JSON.stringify(createOthersMenuDefault))
    }
  },
  computed: {
    ...mapState(['roleArray', 'userDetail']),
    operator () {
      return this.userDetail.account
    },
    managerRole () {
      return this.userDetail.role
    },
    sideMenuOptions () {
      return releaseSite.map((route) => ({
        value: route.name,
        text: route.meta.zhTw
      }))
    },
    componentMenuOptions () {
      return releaseTopSite.map((route) => ({
        value: route.name,
        text: route.meta.zhTw
      }))
    },
    siteMenuSortList () {
      return this.sideMenuOptions.map((obj) => obj.value)
    },
    componentMenuSortList () {
      return this.componentMenuOptions.map((obj) => obj.value)
    }
  },
  methods: {
    ...mapActions(['getAllGroups']),
    ...mapMutations(['UPDATE_ROLE_ARRAY']),
    // 排序已選 sideMenu 項目
    sortSelectedSideMenu (groupIdx) {
      if (groupIdx === 'createNew') {
        this.createSideMenu.sort(
          (a, b) =>
            this.siteMenuSortList.indexOf(a) - this.siteMenuSortList.indexOf(b)
        )
      } else {
        this.data[groupIdx].sideMenu.sort(
          (a, b) =>
            this.siteMenuSortList.indexOf(a) - this.siteMenuSortList.indexOf(b)
        )
      }
    },
    // 排序已選 componentMenu 項目
    sortSelectedComponentMenu (groupIdx) {
      if (groupIdx === 'createNew') {
        this.createComponentMenu.sort(
          (a, b) =>
            this.componentMenuSortList.indexOf(a) -
            this.componentMenuSortList.indexOf(b)
        )
      } else {
        this.data[groupIdx].componentMenu.sort(
          (a, b) =>
            this.componentMenuSortList.indexOf(a) -
            this.componentMenuSortList.indexOf(b)
        )
      }
    },
    // 取消勾選 othersMenu 清空項目
    handleSelectedOthersMenuTitle (checked, groupIdx, listIdx) {
      if (!checked && groupIdx === 'createNew') {
        return (this.createOthersMenu[listIdx].list = [])
      }
      if (!checked) {
        return (this.data[groupIdx].othersMenu[listIdx].list = [])
      }
    },
    // 排序已選告警等級項目
    sortSelectedOthersMenuList (groupIdx, listIdx) {
      const sortedRule = (a, b) =>
        this.othersMenuList[listIdx].options.indexOf(a) -
        this.othersMenuList[listIdx].options.indexOf(b)

      if (groupIdx === 'createNew') {
        this.createOthersMenu[listIdx].list =
          this.createOthersMenu[listIdx].list.sort(sortedRule)
      } else {
        this.data[groupIdx].othersMenu[listIdx].list =
          this.data[groupIdx].othersMenu[listIdx].list.sort(sortedRule)
      }
    },
    loadData () {
      // 非 Whole_Admin 不可看見 Whole_Admin 群組
      // 若 data othersMenu 數量與頁面 othersMenu 數量不同 則給 default
      // 若 obj.othersMenu.length > this.othersMenuList.length ...(?)
      const copyRoleArray = JSON.parse(JSON.stringify(this.roleArray))
      const setDefaultOthersMenu = (obj) => {
        if (obj.othersMenu.length < this.othersMenuList.length) {
          obj.othersMenu = this.othersMenuList.map((item, idx) => {
            if (!obj.othersMenu[idx]) {
              // 給 default
              return {
                menuName: item.menuName,
                hasPermission: false,
                list: []
              }
            }
            return obj.othersMenu[idx]
          })
        }
        return obj
      }

      if (this.managerRole === 'Whole_Admin') {
        this.data = copyRoleArray.map(setDefaultOthersMenu)
      } else {
        this.data = copyRoleArray
          .filter((obj) => obj.role !== 'Whole_Admin')
          .map(setDefaultOthersMenu)
      }
    },
    async handleCreateGroupData () {
      // check
      if (this.createRoleName === '') return this.$swal('請輸入等級名稱')
      if (this.data.find((obj) => obj.role === this.createRoleName)) {
        return this.$swal('等級名稱與現有的重複')
      }
      const hasUnfilledCreate = this.createOthersMenu.some(
        (obj) => obj.hasPermission && !obj.list.length
      )
      if (hasUnfilledCreate) return this.$swal('請檢查輸入 可使用項目設定')

      // create
      const createGroup = await createGroupApi(
        this.operator,
        this.createRoleName,
        this.createSideMenu,
        this.createComponentMenu,
        this.createOthersMenu
      )

      if (createGroup?.data?.success) {
        this.createRoleName = ''
        this.createSideMenu = []
        this.createComponentMenu = []
        this.createOthersMenu = JSON.parse(
          JSON.stringify(createOthersMenuDefault)
        )
        this.$swal('新增成功')
        await this.getAllGroups()
        this.loadData()
      } else {
        this.$swal(createGroup.data.msg ?? '新增資料異常')
      }
    },
    async handleUpdateGroupData () {
      // check
      const hasUnfilledAlertLevel = this.data
        .map(
          (obj) =>
            obj.othersMenu[0].hasPermission && !obj.othersMenu[0].list.length
        )
        .includes(true)
      if (hasUnfilledAlertLevel) {
        return this.$swal('請檢查輸入 告警等級顯示項目')
      }

      const hasUnfilledSiteRealtimeInfo = this.data
        .map(
          (obj) =>
            obj.othersMenu[1].hasPermission && !obj.othersMenu[1].list.length
        )
        .includes(true)
      if (hasUnfilledSiteRealtimeInfo) {
        return this.$swal('請檢查輸入 案場即時資訊顯示項目')
      }

      // update
      const promise = []

      this.data.forEach((obj) => {
        promise.push(
          this.updateGroup(
            obj.role,
            obj.sideMenu,
            obj.componentMenu,
            obj.othersMenu,
            obj.id
          )
        )
      })

      const success = await Promise.all(promise)
      if (success.every((isTrue) => isTrue)) {
        this.$swal('更新成功')
        await this.getAllGroups()
        this.UPDATE_ROLE_ARRAY()
        this.loadData()
      } else {
        this.$swal('更新資料異常')
      }
    },
    async updateGroup (role, sideMenu, componentMenu, othersMenu, id) {
      const updateGroup = await updateGroupApi(
        this.operator,
        role,
        sideMenu,
        componentMenu,
        othersMenu,
        id
      )
      if (updateGroup?.data?.success) return true
    },
    async handleDeleteGroupData (role, id) {
      // check delete
      this.$swal
        .fire({
          title: `確定要刪除此群組?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `確定`,
          denyButtonText: `否`
        })
        .then((result) => result.isConfirmed && this.deleteGroup(role, id))
    },
    async deleteGroup (role, id) {
      const deleteGroup = await deleteGroupApi(this.operator, role, id)
      if (deleteGroup?.data?.success) {
        this.$swal('刪除成功')
        await this.getAllGroups()
        this.loadData()
      }
    }
  },
  async mounted () {
    this.loadData()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
:deep(.selection) {
  padding-left: 20px;
  width: 380px;

  // cover vue-multiselect style
  .multiselect {
    color: #35495e;
  }

  .multiselect__tags {
    border-radius: 4px;
  }

  .multiselect__tag {
    background: #7db9e8;
    margin-bottom: 2px;
    border-radius: 4px;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #2d738f;
  }

  .multiselect__option--highlight {
    background: #7db9e8;
    outline: none;
    color: white;
  }

  .multiselect__option--highlight:after {
    content: attr(data-select);
    background: #7db9e8;
    color: white;
  }

  .multiselect__option--selected {
    background: #527ea1;
    color: white;
    font-weight: 700;
  }

  .multiselect__option--selected:after {
    content: "selected";
    background: #527ea1;
    color: white;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #b3b6b9;
    color: #fff;
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: #b3b6b9;
    content: attr(data-deselect);
    color: #fff;
  }

  .multiselect__placeholder {
    color: #9fa2a4;
    margin-bottom: 0;
    margin-left: 3px;
    padding-top: 1px;
    letter-spacing: 0.5px;
    font-size: 15px;
  }

  .multiselect__input::placeholder {
    color: #9fa2a4;
  }

  .multiselect__single {
    color: #434445;
  }
}
:deep(.invalid) {
  .typo__label {
    color: #f04124;
    font-size: 14px;
    padding-left: 12px;
  }
  .multiselect__tags {
    border-color: #f04124;
  }
}
</style>

<style lang="scss" scoped>
table td {
  font-size: 17px;
  font-weight: 700;
  padding: 5px 5px;
  min-width: 220px;
  width: 18%;
}

// 可觀看 Side
table td:nth-child(2) {
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
  min-width: 200px;
}

// 可觀看 Top
table td:nth-child(3) {
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
  min-width: 200px;
}

// 可使用項目
table td:nth-child(4) {
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
  min-width: 300px;
  width: 20%;
}

// 設定
table td:nth-child(5) {
  width: 10%;
  min-width: 150px;
}

.tableWrap {
  height: calc(100vh - 210px);
  overflow-x: auto;
  min-height: 450px;

  table {
    th {
      background-color: rgb(246, 247, 245);
      font-size: 17px;
    }
    tr {
      border-bottom: 2px solid rgba(190, 195, 177, 0.37);
    }
  }
  @media screen and (max-width: 575.98px) {
    height: calc(100vh - 260px);
  }
}
</style>
